// preloader
$(window).bind("load", function() {
   $('.preloader').fadeOut('slow');
});
//end preloader

// nav menubar
$(window).on("scroll", function() {
        if($(window).scrollTop() > 50) {
            $("header").addClass("headscroll");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
           $("header").removeClass("headscroll");
        }
    });
// end nav menubar

$(document).ready(function() {
	
//Side bar
$(".sidebar_nav").click(function() {
  $('.SideMenuWrap').toggleClass("MenuTranslate");
  $('.toggle-button').toggleClass('change');
  $('main').toggleClass('translateMain');
  $('footer').toggleClass('translateFoot');
  $('header').toggleClass('translateHead');
  $('.overlay').toggle();
});

$(".overlay").click(function() {
  $('.SideMenuWrap').removeClass("MenuTranslate");
  $('.toggle-button').removeClass('change');
  $('footer').toggleClass('translateFoot');
  $('main').removeClass('translateMain');
  $('header').removeClass('translateHead');
  $('.overlay').hide();
});
// End Sidebar

// Accordion FAQ
$(function() {
  $('.acc_ctrl').on('click', function(e) {
    e.preventDefault();
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $(this).next()
      .stop()
      .slideUp(300);
    } else {
      $(this).addClass('active');
      $(this).next()
      .stop()
      .slideDown(300);
    }
  });
});
// end Accordion FAQ

// tabs
$(function() {
  $('.tabs nav a').on('click', function() {
    show_content($(this).index());
  });
  
  show_content(0);

  function show_content(index) {
    // Make the content visible
    $('.tabs .content.visible').removeClass('visible');
    $('.tabs .content:nth-of-type(' + (index + 1) + ')').addClass('visible');

    // Set the tab to selected
    $('.tabs nav a.selected').removeClass('selected');
    $('.tabs nav a:nth-of-type(' + (index + 1) + ')').addClass('selected');
  }
});
// end tabs 
});

var headspace = Headspace(document.querySelector('header'));

// Advanced example with options:
Headspace(document.querySelector('header'), { // can use factory method or `new Headspace`
  startOffset: 50,                            // default: height of element
  tolerance: 5,                               // default: 8
  showAtBottom: false,                        // default: true
  classNames: {
    base: 'custom',                           // default: 'headspace'
    fixed: 'custom--fixed',                   // default: 'headspace--fixed'
    hidden: 'custom--hidden'                  // default: 'headspace--hidden'
  }
});



